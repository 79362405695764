export enum OptimizelyFeatureFlag {
  SignupMessagingRules = 'fe_signup_messaging_rules',
  MetricsV2 = 'metrics_v2_early_adopter',
  DirectReservationAgreements = 'direct_rental_agreements',
  GuestExperienceAi = 'ai_gx',
  BillingDeviceAddon = 'billing_device_addon',
  AirbnbPMSScope = 'airbnb_pms_scope',
  SmartCodes = 'ai_smart_codes',
  OwnerStatements = 'fe_owner_statements',
  DirectInstantBook = 'frontend_direct_instant_book',
  OnboardingBanner = 'onboarding_banner_enabled',
  BillingDisabled = 'ui_billing_disabled',
  Agoda = 'agoda_pilot',
  BookingComPropertyManagment = 'booking_com_property_management',
  AirbnbNotifications = 'airbnb_notifications',
  AirbnbOpportunities = 'airbnb_opportunities',
}

export enum LogicalOperator {
  AND = 'AND',
  OR = 'OR',
}

export interface OptimizelyFeatureSwitchMeta {
  [OptimizelyFeatureFlag.SignupMessagingRules]?: {
    scenarios: {
      key: string;
      description: string;
      criteria: {
        properties: number[];
        rulesSelected: number[];
        logicalOperator: LogicalOperator;
      };
      primaryCta: number;
      secondaryCta: number | null;
    }[];
  };
}

export enum PosthogFeatureFlag {
  PaymentTerms = 'payment_terms',
  Thermostats = 'thermostats_early_access',
  Upsells = 'upsells',
  DirectVideoHeader = 'direct-video-header',
  AccountingIntegrations = 'accounting_integrations',
  YearInReviewCta = 'year_in_review_cta',
  EarningReports = 'earning-reports',
  SentimentAnalysis = 'sentiment_analysis',
  SignupV2 = 'signup-v2',
  CalendarOptimisticUpdate = 'calendar-optimistic-updates',
  MobileNavTabs = 'mobile-nav-tabs',
  OwnerStatementsAdjustments = 'os_reservation_adjustments',
}
