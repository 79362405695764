import { Injectable, isDevMode } from '@angular/core';
import { config } from '@app/core/app-config';
import { UserPermissionsService } from '@app/core/user-permissions/user-permissions.service';
import { OptimizelyFeatureFlag } from '@app/shared/interfaces';
import { decodeToRealUserId, Logger, retrieveUserFromLocalStorage } from '@app/shared/utils';
import { BehaviorSubject, Observable, of, race, timer } from 'rxjs';
import { filter, first, map, take } from 'rxjs/operators';
import { ScriptLoaderService } from '../script-loader/script-loader.service';

@Injectable({
  providedIn: 'root',
})
export class OptimizelyService {
  constructor(
    private scriptLoader: ScriptLoaderService,
    private userPermissionsService: UserPermissionsService
  ) {
    this.userPermissionsService
      .isPrimaryUser()
      .pipe(filter((isPrimaryUser) => isPrimaryUser !== null && isPrimaryUser !== undefined))
      .subscribe((isPrimaryUser) => {
        this.isPrimaryUser = isPrimaryUser;
      });
  }

  /**
   * Internal state to track if the optimizely library has loaded
   */
  private optimizelyClientInstance: any;
  public failedToLoad: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private readonly _loaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loaded = this._loaded.asObservable();
  isPrimaryUser = true;

  /**
   * =============================
   * Currently used feature flags
   * =============================
   */
  public ownerStatementsEnabled$ = this.featureEnabled$(OptimizelyFeatureFlag.OwnerStatements);
  public airbnbPmsFlowEnabled$ = this.featureEnabled$(OptimizelyFeatureFlag.AirbnbPMSScope);
  public onboardingBannerEnabled$ = this.featureEnabled$(OptimizelyFeatureFlag.OnboardingBanner);
  public billingDisabled$ = this.featureEnabled$(OptimizelyFeatureFlag.BillingDisabled);
  public guestExperienceAiEnabled$ = this.featureEnabled$(OptimizelyFeatureFlag.GuestExperienceAi);
  public agodaEnabled$ = this.featureEnabled$(OptimizelyFeatureFlag.Agoda);
  public bookingComPropertyManagementEnabled$ = this.featureEnabled$(OptimizelyFeatureFlag.BookingComPropertyManagment);

  public init() {
    this.scriptLoader
      .load({
        name: 'Optimizely SDK',
        src: `https://unpkg.com/@optimizely/optimizely-sdk/dist/optimizely.browser.umd.min.js`,
      })
      .subscribe(
        (res) => {
          (window as any).optimizelySdk.setLogLevel('error');
          this.optimizelyClientInstance = (window as any).optimizelySdk.createInstance({
            sdkKey: config.OPTIMIZELY_SDK_KEY,
            datafileOptions: {
              autoUpdate: true,
              updateInterval: 60000, // 1 minutes in milliseconds
            },
          });

          // Can be 'info', 'debug', 'warn', 'error'

          // Provide a timeout in milliseconds - promise will resolve if the datafile still is not available after the timeout
          this.optimizelyClientInstance.onReady({ timeout: 5000 }).then((result) => {
            // Returned Promise is fulfilled with a result object
            if (!result.success) {
              this.failedToLoad.next(true);
            } else {
              this.getAllEnabledFeatures();
            }
          });
        },
        (error) => {
          console.log(error);
          this.failedToLoad.next(true);
        }
      );
  }

  private generateOptimizelyAttributes(): { UID: string; is_primary_user: boolean; is_beta_user: boolean } {
    const userObj = JSON.parse(localStorage.getItem('user'));

    return {
      UID: this.getUserId(),
      is_primary_user: this.isPrimaryUser,
      is_beta_user: userObj?.beta_features_enabled || false,
    };
  }

  private getAllEnabledFeatures() {
    const resultArr: string[] = this.optimizelyClientInstance.getEnabledFeatures(
      this.getUserId(),
      this.generateOptimizelyAttributes()
    );

    localStorage.setItem('feature-flags', JSON.stringify(resultArr));

    this._loaded.next(true);
  }

  /**
   * Async version that waits to make sure Optimizely is loaded
   * before checking feature flags
   *
   * To use locally, you can add a flag to your environment.ts file
   *
   * @param feature Feature to check is enabled
   * @returns Boolean observable of whether the feature is enabled
   */
  public featureEnabled$(feature: OptimizelyFeatureFlag, timeToWait = 5000): Observable<boolean> {
    if (isDevMode() && config.OPTIMIZELY_FEATURE_FLAGS?.length) {
      Logger.log('Optimizely is using dev mode, set flags in your environment.ts file');
      return of(Boolean(config.OPTIMIZELY_FEATURE_FLAGS.find((f) => f === feature)));
    }
    return race(
      this.loaded.pipe(
        filter((loaded) => Boolean(loaded)),
        take(1),
        map(() => this.isFeatureEnabled(feature))
      ),
      // If the user has blocked Optimizely, we might never get a loaded event
      // In that case, we should just return false
      timer(timeToWait).pipe(
        first(),
        map(() => false)
      )
    );
  }

  /**
   * @deprecated - for private use only, use featureEnabled$ instead
   */
  private isFeatureEnabled(feature: OptimizelyFeatureFlag): boolean {
    if (!this._loaded.value) {
      // Do we already have the feature array in localStorage, we can pull from that as a backup?
      let ls;

      try {
        ls = JSON.parse(localStorage.getItem('feature-flags'));
      } catch (e) {}

      if (Array.isArray(ls) && ls.length > 0) {
        return ls.includes(feature);
      }

      // Nope, we are out of luck
      Logger.info(`Optimizely was called but is not initialized`);
      return false;
    }

    return this.optimizelyClientInstance.isFeatureEnabled(
      feature,
      this.getUserId(),
      this.generateOptimizelyAttributes()
    );
  }

  private getFeatureVariable<F>(feature: string): F {
    if (!this._loaded.value) {
      // Do we already have the feature array in localStorage, we can pull from that as a backup?
      let ls;

      try {
        ls = JSON.parse(localStorage.getItem('feature-flags'));
      } catch (e) {}

      if (Array.isArray(ls) && ls.length > 0) {
        return ls[feature];
      }

      // Nope, we are out of luck
      Logger.info(`Optimizely was called but is not initialized`);
      return;
    }

    return this.optimizelyClientInstance.getFeatureVariable(
      feature,
      this.getUserId(),
      this.generateOptimizelyAttributes()
    );
  }

  private getUserId(): string {
    const { id, email } = retrieveUserFromLocalStorage();
    return decodeToRealUserId(id, email) ?? '';
  }
}
