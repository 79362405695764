import { Directive, OnDestroy } from "@angular/core";
import { ReplaySubject, Subject } from "rxjs";

@Directive()
export class OnDestroyComponent implements OnDestroy{

  destroyed$ = new ReplaySubject<boolean>(1);

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
};