import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'sbnb-ui-page',
  template: `
    <div class="bg-grape-darker min-h-screen">
      <button
        *ngIf="!drawer.opened"
        type="button"
        mat-button
        (click)="drawer.toggle()"
        class="!fixed top-4 left-4 z-20">
        <sbnb-icon icon="hamburger-active"></sbnb-icon>
      </button>
      <mat-drawer-container [hasBackdrop]="true">
        <mat-drawer #drawer mode="side">
          <div class="flex flex-col p-10 bg-smoke-lighter h-screen fixed top-0 left-0">
            <sbnb-close class="absolute top-3 right-3" (click)="drawer.close()"></sbnb-close>
            <ng-container *ngTemplateOutlet="navLinks"></ng-container>
          </div>
        </mat-drawer>

        <div class="flex flex-col items-center justify-center p-10 ">
          <router-outlet #outlet="outlet"></router-outlet>

          <div *ngIf="!outlet.isActivated" class="bg-smoke-lighter p-10 rounded-md">
            <ng-container *ngTemplateOutlet="navLinks"></ng-container>
          </div>
        </div>
      </mat-drawer-container>
    </div>

    <ng-template #navLinks>
      <div class="flex flex-col gap-2">
        <a
          [routerLink]="['/ui/icons']"
          (click)="drawer.close()"
          class="flex items-center gap-2 link"
          routerLinkActive="link-active">
          <sbnb-icon icon="arrow-right"></sbnb-icon>
          <span>Icons</span>
        </a>
        <a
          [routerLink]="['/ui/segment-events']"
          routerLinkActive="link-active"
          (click)="drawer.close()"
          class="flex items-center gap-2 link">
          <sbnb-icon icon="arrow-right"></sbnb-icon>
          <span>Segment events</span>
        </a>
      </div>
    </ng-template>
  `,
  styles: [
    `
      @import 'variables';
      .link-active {
        text-decoration: none;
        color: $night;
        pointer-events: none;
        font-family: 'Cera Headings';
      }

      ::ng-deep .mat-drawer-container {
        overflow: auto !important;
        display: initial !important;
      }

      ::ng-deep .mat-drawer-content {
        overflow: auto !important;
        display: initial !important;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiPageComponent implements OnInit {
  constructor() { }

  ngOnInit(): void { }
}
