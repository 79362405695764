import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DirectSiteStatus } from '@app/shared/interfaces';

@Pipe({
  name: 'friendlyPublishStatus',
})
@Injectable({
  providedIn: 'root',
})
export class FriendlyPublishStatusPipe implements PipeTransform {
  FRIENDLY_STATUSES = {
    PUBLISHED: 'Live',
    NOT_PUBLISHED_YET: 'Draft',
    UNPUBLISHED: 'Draft',
  };

  transform(publishStatus: string): DirectSiteStatus {
    return this.FRIENDLY_STATUSES[publishStatus] ?? 'Unknown';
  }
}
