<div
  class="notification__wrapper"
  *ngIf="
    relevantNotifications &&
    relevantNotifications.length > 0 &&
    excludedPages.indexOf(url) === -1 &&
    (navExists || bypassNavCheck)
  ">
  <ng-container *ngFor="let noti of relevantNotifications; trackBy: trackNotification">
    <ng-container [ngSwitch]="noti.type">
      <div *ngSwitchCase="NotificationDataType.CONTENT" class="notification--content">
        <div fxLayout fxLayoutAlign="space-between center" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start start">
          <p class="notification__description leading-normal" [innerHTML]="noti.description | safe: 'html'"></p>
        </div>

        <div *ngIf="noti.actions && noti.actions.length > 0" fxLayout class="notification--content-actions-container">
          <ng-container *ngFor="let action of noti.actions">
            <div *ngIf="action.type === 'button' && action.url">
              <a
                [class.action__primary]="action.color === 'primary'"
                [class.action__secondary]="action.color === 'secondary'"
                [class.action__text]="action.color === 'text'"
                (click)="openLink(action, noti)">
                <img *ngIf="action.icon" src="/assets/iconography/{{ action.icon }}.svg" onload="SVGInject(this)" />
                {{ action.text }}
              </a>
            </div>

            <div
              *ngIf="
                action.type === 'button' &&
                action.action === 'product_tour' &&
                ((!isMobile && action.product_tour_id) || (isMobile && action.mobile_product_tour_id))
              ">
              <a
                [class.action__primary]="action.color === 'primary'"
                [class.action__secondary]="action.color === 'secondary'"
                [class.action__text]="action.color === 'text'"
                (click)="startTour(!isMobile ? action.product_tour_id : action.mobile_product_tour_id, action, noti)">
                <img *ngIf="action.icon" src="/assets/iconography/{{ action.icon }}.svg" onload="SVGInject(this)" />
                {{ action.text }}
              </a>
            </div>
          </ng-container>
          <sbnb-badge class="badge-space" *ngIf="noti | isUpdatedWithin30Days" context="positive">NEW</sbnb-badge>
        </div>
        <mat-divider class="helptext-divider"></mat-divider>
      </div>

      <div *ngSwitchCase="NotificationDataType.SIDEBAR" class="card__container">
        <div class="card">
          <div class="flex items-center gap-2">
            <sbnb-icon icon="help" class="text-raspberry"></sbnb-icon>
            <h4 class="nomargin flex-1">{{ noti.title }}</h4>
          </div>
          <p class="notification__description" [innerHTML]="noti.description | safe: 'html'"></p>
        </div>
        <ng-container *ngFor="let action of noti.actions">
          <a
            *ngIf="action.type === 'button' && action.url"
            [href]="action.url"
            target="_blank"
            class="card__btn-footer">
            {{ action.text }}
          </a>
          <a
            *ngIf="
              action.type === 'button' &&
              action.action === 'product_tour' &&
              ((!isMobile && action.product_tour_id) || (isMobile && action.mobile_product_tour_id))
            "
            fxLayoutAlign="center center"
            (click)="startTour(!isMobile ? action.product_tour_id : action.mobile_product_tour_id, action, noti)"
            class="card__btn-footer">
            <img class="tour__image" src="/assets/iconography/walkthrough.svg" />
            <span>{{ action.text }}</span>
          </a>
          <div
            *ngIf="action.type === 'dismissible'"
            class="card__dismiss"
            (click)="dismissNotification(action.url, noti)">
            <img src="/assets/iconography/cancel.svg" />
          </div>
        </ng-container>
      </div>

      <div *ngSwitchCase="NotificationDataType.INTRO" class="rules__intro-text">
        <h2>{{ noti.title }}</h2>

        <div
          fxLayout
          fxLayoutGap="24px"
          fxLayoutAlign="space-between center"
          fxLayout.lt-md="column"
          fxLayoutAlign="space-between center">
          <p class="notification__description" [innerHTML]="noti.description | safe: 'html'"></p>
          <img *ngIf="noti.image" class="intro-companion-img" [src]="noti.image" />
        </div>

        <div
          *ngIf="noti.actions && noti.actions.length > 0"
          fxLayout
          class="intro-actions-container"
          fxLayout.lt-md="column"
          fxLayoutGap="16px">
          <ng-container *ngFor="let action of noti.actions">
            <button
              *ngIf="action.type === 'button' && action.url"
              mat-flat-button
              [class.button__secondary]="action.color === 'secondary'"
              [class.button__text]="action.color === 'text'"
              (click)="redirectToUrl(action.url)">
              <a [class.white]="action.color === 'primary'">{{ action.text }}</a>
            </button>

            <button
              mat-flat-button
              *ngIf="
                action.type === 'button' &&
                action.action === 'product_tour' &&
                ((!isMobile && action.product_tour_id) || (isMobile && action.mobile_product_tour_id))
              "
              fxLayoutAlign="center center"
              (click)="startTour(!isMobile ? action.product_tour_id : action.mobile_product_tour_id, action, noti)">
              <img class="margin-right-8" src="/assets/iconography/walkthrough-white.svg" />
              <span [class.white]="action.color === 'primary'">{{ action.text }}</span>
            </button>

            <div
              *ngIf="action.type === 'dismissible'"
              class="card__dismiss"
              (click)="dismissNotification(action.url, noti)">
              <button
                mat-flat-button
                class="button__secondary button__small"
                (click)="dismissNotification(action.url, noti)">
                dismiss
              </button>
            </div>
          </ng-container>
        </div>
      </div>

      <div
        *ngSwitchCase="NotificationDataType.DEFAULT"
        class="notification__default notification__{{ noti.tag }}"
        [class.nav_expanded]="navExpanded"
        fxLayout
        fxLayoutAlign="start center"
        fxLayoutGap="16px"
        [fxLayout.lt-md]="noti.tag === 'global' ? 'row' : 'column'"
        fxLayoutAlign.lt-md="start start">
        <p
          class="nomargin notification__default-title strong ellipsis"
          [fxFlex]="noti.tag === 'global' ? 'initial' : 'none'">
          {{ noti.title }}
        </p>
        <p
          class="notification__description text__small nomargin"
          [class.ellipsis]="noti.tag === 'global'"
          fxFlex
          [innerHTML]="noti.description | safe: 'html'"></p>
        <div
          [fxFlex]="noti.tag === 'global' ? 'none' : 'none'"
          [fxFlex.lt-md]="noti.tag === 'global' ? 'noshrink' : 'none'"
          *ngIf="noti.actions && noti.actions.length > 0"
          fxLayout
          fxLayoutAlign="start center"
          fxLayoutGap="8px">
          <ng-container *ngFor="let action of noti.actions">
            <button
              *ngIf="action.type === 'button' && action.url"
              mat-flat-button
              class="button__small button__secondary">
              <a (click)="openLink(action, noti)">{{ action.text }}</a>
            </button>

            <button
              *ngIf="
                action.type === 'button' &&
                action.action === 'product_tour' &&
                ((!isMobile && action.product_tour_id) || (isMobile && action.mobile_product_tour_id))
              "
              mat-flat-button
              class="button__small button__secondary"
              (click)="startTour(!isMobile ? action.product_tour_id : action.mobile_product_tour_id, action, noti)">
              <img class="margin-right-8" src="/assets/iconography/walkthrough.svg" />
              <span>{{ action.text }}</span>
            </button>
          </ng-container>

          <ng-container *ngFor="let action of noti.actions">
            <div
              *ngIf="action.type === 'dismissible'"
              class="card__dismiss-default clickable"
              (click)="dismissNotification(action.url, noti)">
              <img src="/assets/iconography/cancel.svg" />
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
