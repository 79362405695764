import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SvgCacheService } from '@app/ui';
import { sbnbIcon } from '@app/ui';
import { ClipboardService } from 'ngx-clipboard';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';

/**
 * See the README.md in the icons directory for more information on how to convert an icon to use current color.
 */

const USES_CURRENT_COLOR: Partial<Record<sbnbIcon, boolean>> = {
  'accounting-coins-stack': true,
  'ads-click': true,
  adults: true,
  'alarm-bell-check': true,
  'alarm-bell-off': true,
  'alert-circle': true,
  'alert-triangle': true,
  'apps-simple': true,
  'book-open': true,
  'book-open-bold': true,
  calendar: true,
  'calendar-availability': true,
  'calendar-simple': true,
  check: true,
  'check-circle': true,
  cog: true,
  'cog-simple': true,
  details: true,
  devices: true,
  edit: true,
  email: true,
  heart: true,
  'heart-simple': true,
  help: true,
  'help-simple': true,
  hyperlink: true,
  key: true,
  'key-simple': true,
  'language-translation': true,
  'light-bulb-shine': true,
  'like-bubble': true,
  'matches-fire': true,
  message: true,
  'message-simple': true,
  metrics: true,
  'metrics-simple': true,
  more: true,
  'nav-direct': true,
  'not-allowed': true,
  ops: true,
  'ops-simple': true,
  padlock: true,
  phone: true,
  'pin-add-1': true,
  'post-stay': true,
  preferences: true,
  'preferences-simple': true,
  present: true,
  properties: true,
  'properties-simple': true,
  resolve: true,
  'recurring-financial': true,
  scope: true,
  search: true,
  send: true,
  star: true,
  subscription: true,
  'subscription-simple': true,
  sync: true,
  tag: true,
  task: true,
  'temperature-snowflake': true,
  time: true,
  trash: true,
  'users-simple': true,
  users: true,
};

@Component({
  selector: 'sbnb-icons-page',
  template: `
    <div class="w-full flex items-center justify-center bg-grape-darker sticky top-0 z-10 py-2 box-shadow-heavy">
      <mat-form-field class="!text-white nopadding">
        <mat-label>Search icons</mat-label>
        <mat-icon matPrefix>search</mat-icon>
        <input [formControl]="searchControl" type="text" matInput placeholder="Search..." />
      </mat-form-field>
    </div>
    <div class="grid gap-1 grid-cols-2 md:grid-cols-4 lg:grid-cols-8 bg-grape-darker mt-2 mb-12 p-1">
      <div
        *ngFor="let icon of filteredIcons$ | async"
        class="icon-cell px-2 py-4 flex flex-col gap-2 items-center justify-center rounded-sm bg-white">
        <ng-container *ngIf="copiedMap$ | async as copiedMap">
          <div class="copy-component cursor-pointer" (click)="copyComponent(icon)" [matTooltip]="'Copy component code'">
            <sbnb-icon *ngIf="!copiedMap[icon]" icon="copy-grass"></sbnb-icon>
          </div>
          <div
            *ngIf="copiedMap[icon]"
            class="text__xsmall bg-grape-darker text-white p-2 rounded-md absolute top-1 right-1">
            Copied!
          </div>
        </ng-container>

        <sbnb-icon [icon]="icon" [size]="36"></sbnb-icon>
        <code class="text__xsmall break-all night-lighter">{{ icon }}</code>
        <div *ngIf="usesCurrentColor[icon]" class="absolute bottom-0 left-3" matTooltip="Can be styled with CSS">
          <sbnb-icon [size]="16" icon="thumbs-up"></sbnb-icon>
        </div>
      </div>
    </div>
    <mat-card [matTooltip]="progressTooltip">
      <mat-card-content>
        <h3>Percentage of icons styled with CSS</h3>

        <section class="mt-4">
          <mat-progress-bar mode="determinate" [value]="progressValue"></mat-progress-bar>
        </section>
      </mat-card-content>
    </mat-card>
  `,
  styles: [
    `
      ::ng-deep .mat-input-element {
        color: white !important;
      }

      ::ng-deep .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
        color: #fff !important;
      }

      .copy-component {
        display: none;
        position: absolute;
        top: 4px;
        right: 4px;
      }
      .icon-cell {
        position: relative;
        &:hover {
          .copy-component {
            display: block;
          }
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconsPageComponent implements OnInit {
  public usesCurrentColor = USES_CURRENT_COLOR;
  public icons;
  public copiedMap$ = new BehaviorSubject<Record<sbnbIcon, boolean>>({} as Record<sbnbIcon, boolean>);
  public searchControl = new FormControl('');
  public filteredIcons$ = this.searchControl.valueChanges.pipe(
    startWith(''),
    debounceTime(300),
    distinctUntilChanged(),
    map((search?: string) => {
      if (!search?.length) {
        return this.icons;
      }
      const modSearch = search.toLowerCase().replace(/[-_]/g, ' ');
      return this.icons.filter((icon) => icon.replace(/-/g, ' ').includes(modSearch));
    })
  );

  public progressValue = 0;
  public progressTooltip = 'Percentage of icons styled with CSS';

  constructor(
    private svgCacheService: SvgCacheService,
    private clipboard: ClipboardService
  ) {}

  ngOnInit(): void {
    this.icons = this.svgCacheService.getAllIcons();
    this.progressValue = Math.round((Object.keys(USES_CURRENT_COLOR).length / this.icons.length) * 100);
    this.progressTooltip = `${Object.keys(USES_CURRENT_COLOR).length} of ${this.icons.length} icons (${this.progressValue}%) can be styled with CSS`;
  }

  public copyComponent(icon: sbnbIcon): void {
    const text = `<sbnb-icon icon="${icon}"></sbnb-icon>`;
    this.clipboard.copy(text);
    this.copiedMap$.next({ ...this.copiedMap$.value, [icon]: true });
    setTimeout(() => {
      this.copiedMap$.next({ ...this.copiedMap$.value, [icon]: false });
    }, 2000);
  }
}
