<div
  class="flex justify-around border-t border-gray-200 bg-white shadow-lg nav-tabs"
  [ngStyle]="{ '  grid-template-columns': 'repeat(' + (2 + (navItems?.length || 0)) + ', 1fr)' }">
  <ng-container *ngIf="this.currentUser$ | async">
    <div class="flex flex-col items-center justify-center text-gray-950" [routerLink]="['/dashboard']">
      <a [routerLink]="['/dashboard']" routerLinkActive="linkActive" class="flex flex-col items-center">
        <sbnb-icon icon="hospitable" class="mb-1"></sbnb-icon>
        <span>Home</span>
      </a>
    </div>
    <ng-container *ngFor="let item of navItems">
      <div class="flex flex-col items-center justify-center text-gray-950">
        <a [routerLink]="[item.link]" routerLinkActive="linkActive" class="flex flex-col items-center">
          <div class="relative">
            <sbnb-icon [icon]="item.icon" class="mb-1"></sbnb-icon>
            <div *ngIf="item.badgeCountKey && badgeCounts && badgeCounts[item.badgeCountKey] > 0" class="badge">
              {{ badgeCounts[item.badgeCountKey] > 99 ? '99+' : badgeCounts[item.badgeCountKey] }}
            </div>
          </div>
          <span>{{ item.expandedText }}</span>
        </a>
      </div>
    </ng-container>
    <div class="flex flex-col items-center justify-center text-gray-950">
      <a
        [routerLink]="['/menu-mobile']"
        routerLinkActive="linkActive"
        class="flex flex-col items-center menu-tab-option">
        <sbnb-icon icon="hamburger" class="mb-1"></sbnb-icon>
        <span>Menu</span>
      </a>
    </div>
  </ng-container>
</div>
