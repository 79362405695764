<app-feature-flag-tabs>
  <div fxHide fxShow.lt-md class="nav_wrapper-mobile" [ngStyle]="{ 'background-color': bgMobile || '#ffffff' }">
    <img class="clickable" (click)="showMobileNav()" src="/assets/iconography/hamburger-active.svg" alt="open menu" />
  </div>
</app-feature-flag-tabs>

<div
  *ngIf="initialized"
  #mainNavigation
  class="nav_wrapper hidden sbnbDesktop:flex flex-none flex-col justify-between items-start"
  [class.nav_expanded]="(expanded && !overrideExpanded) || (mobileMenuVisible$ | async)"
  [class.mobile-visible]="mobileMenuVisible$ | async"
  >
  <sbnb-resizer
    class="nav__resizer"
    *ngIf="(mobileMenuVisible$ | async) === false"
    [isExpanded]="(expanded && !overrideExpanded) || (mobileMenuVisible$ | async)"
    (onToggle)="expandChange()"></sbnb-resizer>

  <div style="width: 100%">
    <header class="nav_header">
      <a
        [routerLink]="['/dashboard']"
        routerLinkActive="header-active-link"
        fxLayout
        fxLayoutAlign="center center"
        fxLayoutGap="12px">
        <div class="logo__palantir" fxFlex="40px" fxLayoutAlign="center center">
          <img width="28px" height="28px" [src]="palantirSrc" alt="Hospitable logo" />
        </div>
        <div>
          <div *ngIf="(expanded && !overrideExpanded) || (mobileMenuVisible$ | async)">
            <img
              class="nav__header-logo"
              [@enterAnimation]
              [@.disabled]="!animEnabled"
              src="/assets/images/smartbnb-header-white.svg"
              width="104"
              style="margin-top: 6px; height: 20px"
              onload="SVGInject(this)"
              alt="Hospitable logo" />
          </div>
        </div>
      </a>
    </header>
    <nav class="nav__main">
      <div *ngFor="let item of navItems">
        <a
          *ngIf="item.position === 'top' && !item.hidden && !item.external"
          [class.disabled]="item.hidden"
          [routerLink]="item.link"
          style="width: 100%"
          #rla="routerLinkActive"
          routerLinkActive="active-link"
          [class.active-link]="url.indexOf('/demo' + item.link) > -1"
          (click)="hideMobileMenu()"
          fxLayout
          fxLayoutAlign="start center"
          class="nav-parent ellipsis {{ item.class }}"
          id="{{ item.id }}">
          <div class="nav-item flex w-[40px] items-center justify-center">
            <sbnb-icon [size]="item.icon === 'devices' ? 24 : 20" [icon]="item.icon"></sbnb-icon>
            <div *ngIf="item.comingSoon" class="badge badge-soon">Beta</div>
            <div *ngIf="item.badgeCountKey && badgeCounts && badgeCounts[item.badgeCountKey] > 0" class="badge">
              {{ badgeCounts[item.badgeCountKey] > 99 ? '99+' : badgeCounts[item.badgeCountKey] }}
            </div>
          </div>
          <div
            fxFlex=""
            class="nav_text"
            *ngIf="(expanded && !overrideExpanded) || (mobileMenuVisible$ | async)"
            [@enterAnimation]
            [@.disabled]="!animEnabled">
            {{ item.expandedText }}
          </div>
        </a>
        <a
          *ngIf="item.position === 'top' && !item.hidden && item.external"
          [class.disabled]="item.hidden"
          style="width: 100%"
          #rla="routerLinkActive"
          routerLinkActive="active-link"
          (click)="goToExternalLink(item.link)"
          [class.active-link]="url.indexOf('/demo' + item.link) > -1"
          (click)="hideMobileMenu()"
          fxLayout
          fxLayoutAlign="start center"
          class="nav-parent ellipsis clickable {{ item.class }}"
          id="{{ item.id }}">
          <div fxLayoutAlign="center center" class="nav-item" fxFlex="40px">
            <img class="nav__fixed" [src]="'/assets/iconography/nav/' + item.icon + '.svg'" onload="SVGInject(this)" />
            <div *ngIf="item.comingSoon" class="badge badge-soon">Beta</div>
            <div *ngIf="item.badgeCountKey && badgeCounts && badgeCounts[item.badgeCountKey] > 0" class="badge">
              {{ badgeCounts[item.badgeCountKey] > 99 ? '99+' : badgeCounts[item.badgeCountKey] }}
            </div>
          </div>
          <div
            fxFlex=""
            class="nav_text"
            *ngIf="(expanded && !overrideExpanded) || (mobileMenuVisible$ | async)"
            [@enterAnimation]
            [@.disabled]="!animEnabled">
            {{ item.expandedText }}
          </div>
        </a>
        <hr *ngIf="item.separator" />
      </div>
    </nav>
  </div>
  <!-- Bottom nav section -->
  <div class="nav__user" *ngIf="perms$.permissions | async as perms">
    <nav class="nav__secondary">
      <ng-container *ngFor="let item of navItems">
        <a
          *ngIf="item.position === 'bottom'"
          [class.disabled]="item.hidden"
          [routerLink]="item.link"
          style="width: 100%"
          #rla="routerLinkActive"
          routerLinkActive="active-link"
          (click)="hideMobileMenu()"
          fxLayout
          fxLayoutAlign="start center"
          class="nav-parent {{ item.class }}"
          id="{{ item.id }}">
          <div fxLayoutAlign="center center" class="nav-item" fxFlex="40px">
            <sbnb-icon [icon]="item.icon"></sbnb-icon>
            <div
              *ngIf="item.badgeCountKey && badgeCounts && badgeCounts[item.badgeCountKey] > 0"
              class="badge badge-grass">
              {{ badgeCounts[item.badgeCountKey] }}
            </div>
          </div>
          <div
            fxFlex=""
            class="nav_text_secondary"
            *ngIf="(expanded && !overrideExpanded) || (mobileMenuVisible$ | async)"
            [@enterAnimation]
            [@.disabled]="!animEnabled">
            {{ item.expandedText }}
          </div>
        </a>
      </ng-container>

      <a
        *ngIf="perms?.is_admin || perms?.is_team_owner"
        [matMenuTriggerFor]="settingsMenu"
        style="width: 100%"
        fxLayout
        [class.active-link]="url.indexOf('/settings') > -1"
        fxLayoutAlign="start center"
        class="nav-parent clickable">
        <div fxLayoutAlign="center center" class="nav-item" fxFlex="40px">
          <sbnb-icon icon="cog-simple"></sbnb-icon>
        </div>
        <div
          fxFlex=""
          class="nav_text_secondary"
          *ngIf="(expanded && !overrideExpanded) || (mobileMenuVisible$ | async)"
          [@enterAnimation]
          [@.disabled]="!animEnabled">
          Settings
        </div>
      </a>

      <mat-menu
        #settingsMenu="matMenu"
        class="user__menu"
        [class]="expanded && !overrideExpanded ? 'settings-menu-expanded' : 'settings-menu-collapsed'">
        <button
          *ngIf="perms?.is_team_owner"
          mat-menu-item
          routerLink="/settings/your-plan"
          fxLayoutAlign="start center"
          fxLayoutGap="10px"
          id="nav-settings-subscription">
          <sbnb-icon icon="subscription-simple" [size]="18" class="pr-2 text-smoke-darker"></sbnb-icon>
          <span>Subscription</span>
        </button>
        <button
          *ngIf="perms?.is_admin"
          mat-menu-item
          routerLink="/accounts"
          fxLayoutAlign="start center"
          fxLayoutGap="10px"
          id="nav-settings-connected-accounts">
          <sbnb-icon icon="key-simple" [size]="18" class="pr-2 text-smoke-darker"></sbnb-icon>
          <span>Connected Accounts</span>
        </button>
        <button
          *ngIf="perms?.is_team_owner"
          mat-menu-item
          routerLink="/settings/user-management"
          fxLayoutAlign="start center"
          fxLayoutGap="10px"
          id="nav-settings-user-management">
          <sbnb-icon icon="users-simple" [size]="18" class="pr-2 text-smoke-darker"></sbnb-icon>
          <span>User Management</span>
        </button>
        <button
          *ngIf="perms?.is_admin"
          mat-menu-item
          routerLink="/settings/general"
          fxLayoutAlign="start center"
          fxLayoutGap="10px"
          id="nav-settings-preferences">
          <sbnb-icon icon="preferences-simple" [size]="18" class="pr-2 text-smoke-darker"></sbnb-icon>
          <span>Preferences</span>
        </button>
      </mat-menu>
    </nav>

    <hr />

    <div
      *ngIf="currentUser$ | async as currentUser"
      class="nav__user-container"
      style="position: relative"
      fxLayout
      fxLayoutAlign="start center"
      fxLayoutGap="12px"
      id="nav-user"
      [matMenuTriggerFor]="userMenu">
      <img
        class="nav__user-profile"
        fxFlex="0 0 32px"
        [src]="currentUser.team.owner_picture"
        [alt]="currentUser.team.owner_first_name"
        onerror="this.src='/assets/images/profile-default.png'" />
      <span *ngIf="!isTeamOwner" class="um__switcher-name-badge">
        {{ currentUser.first_name.charAt(0) }}{{ currentUser.last_name.charAt(0) }}
      </span>
      <span
        fxFlex
        *ngIf="(expanded && !overrideExpanded) || (mobileMenuVisible$ | async)"
        [@enterAnimation]
        [@.disabled]="!animEnabled"
        class="nav_text !ml-4 ellipsis">
        {{ currentUser.first_name }} {{ currentUser.last_name }}
      </span>
      <sbnb-icon *ngIf="!isTeamOwner" [hidden]="!expanded" icon="users-simple" class="pr-2 text-grape"></sbnb-icon>
    </div>

    <mat-menu
      #userMenu="matMenu"
      class="user__menu"
      [class]="expanded && !overrideExpanded ? 'user-menu-expanded' : 'user-menu-collapsed'">
      <span *ngIf="!isTeamOwner">
        <strong class="um__switcher-header">
          {{ teams.length === 1 ? ('CORE.PRODUCT_NAME' | translate) + ' account' : 'Switch account' }}
        </strong>
        <div
          *ngFor="let team of teams"
          class="um__switcher-team"
          [class.um__unselecable]="teams.length === 1"
          fxLayout
          fxLayoutAlign="start center"
          fxLayoutGap="8px"
          (click)="changeActiveTeam(team.team_id)">
          <img
            class="um__switcher-profile"
            [src]="team.picture"
            onerror="this.src='/assets/images/profile-default.png'" />
          <span [class.um__active]="team.active" fxFlex class="ellipsis">{{ team.name }}</span>
        </div>
        <mat-divider class="um__switcher-divider"></mat-divider>
      </span>

      <button
        *ngIf="badgeCounts && badgeCounts['incomplete_onboarding_steps'] > 0"
        mat-menu-item
        routerLink="/onboarding">
        <span class="grass-darker">Get started</span>
      </button>

      <button mat-menu-item routerLink="/settings/profile" id="nav-user-profile">
        <span>My profile</span>
      </button>

      <button *ngIf="isCS" mat-menu-item (click)="setDebugMode()" fxLayout fxLayoutAlign="start center">
        <span fxFlex>{{ (debugMode$ | async) ? 'Disable' : 'Enable' }} debug mode</span>
        <span class="badge">CS</span>
      </button>

      <button *ngIf="isCS" mat-menu-item fxLayout fxLayoutAlign="start center" (click)="enableDemoMode()">
        <span *ngIf="!demoService.isDemo()" fxFlex>Enable demo mode</span>
        <span *ngIf="demoService.isDemo()" fxFlex>Disable demo mode</span>
        <span class="badge">CS</span>
      </button>

      <a href="https://apply.workable.com/hospitable/" target="_blank">
        <button mat-menu-item id="nav-user-hiring">
          <span>We are hiring! &nbsp; 🎉</span>
        </button>
      </a>

      <button
        mat-menu-item
        id="nav-referrals"
        routerLink="/referrals"
        fxLayout
        fxLayoutAlign="start center"
        fxLayoutGap="10px">
        <span>Refer a host</span>
      </button>

      <hr />

      <button mat-menu-item (click)="logout()" id="nav-user-logout">
        <span>Logout</span>
      </button>
    </mat-menu>
  </div>
</div>
