import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { CometlyService } from '@app/shared/services/cometly.service';
import { DemoService } from '@app/shared/services/demo/demo.service';
import { DeviceDetectionService } from '@app/shared/services/device-detection/device-detection.service';
import { PosthogService } from '@app/shared/services/posthog/posthog.service';
import { Logger } from '@app/shared/utils';
import { environment } from '@env/environment';
import { interval, Observable, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { AuthenticationService } from './core/authentication/authentication.service';
import { LocalStorageKey, User } from './shared/interfaces';
import { mobileMenuHideConfig } from '@app/shared/components/menu-mobile-tabs/mobile-menu-hide.config';

@Component({
  selector: 'sbnb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  updatePending: boolean;
  isDemo: boolean;
  isMobile: boolean;
  private destroy$ = new Subject<void>();
  shouldHideMenuBasedOnUrl: boolean = false;
  featureFlagMobileNavTabsEnabled: boolean = false;

  constructor(
    private swUpdate: SwUpdate,
    private demoService: DemoService,
    public authService: AuthenticationService,
    private router: Router,
    public deviceDetectionService: DeviceDetectionService,
    private posthog: PosthogService,
    private cometlyService: CometlyService,
    private renderer: Renderer2,
  ) { }

  ngOnInit() {
    Logger.log(`Hospitable (${environment.name}) - ${environment.version}`);

    this.isDemo = this.demoService.isDemo();
    this.deviceDetectionService.init();
    this.listenForAuth();

    // Posthog
    this.posthog.init();

    // Cometly
    this.cometlyService.init();

    // Listen for App updates via Service worker
    this.checkForUpdate();

    // Check if we have a uuid for 2fa, if not generate a new one
    this.authService.checkFor2faNonce();

    if (this.authService.isLoggedIn() && !window.location.href.includes('user/cs')) {
      this.authService.isLoggedInSubject.next(true);
      this.authService.setCookie(this.authService.cookieStorageKeys.isLoggedIn);
      this.authService.loginActions();
    }

    this.checkIfAnalyticsEnabled();

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      // this.isMobile ? this.handleIntercomMobileWidget() : null;
    });

    this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((event: NavigationStart) => {
      this.shouldHideMenuBasedOnUrl = mobileMenuHideConfig.some(url => event.url.startsWith(url));
    });

    /* remove when mobile nav tabs are stable */
    this.authService.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      if (user) {
        this.posthog.mobileNavTabsEnabled$.pipe(takeUntil(this.destroy$)).subscribe((enabled) => {
          if (enabled) {
            this.featureFlagMobileNavTabsEnabled = true;
            this.renderer.addClass(document.body, 'mobile-nav-tabs-app');
          }
        });
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  checkIfAnalyticsEnabled() {
    if (environment.production && localStorage.getItem(LocalStorageKey.isCS) !== 'true') {
      localStorage.setItem(LocalStorageKey.analyticsEnabled, 'true');
    } else {
      localStorage.removeItem(LocalStorageKey.analyticsEnabled);
    }
  }

  listenForAuth() {
    this.authService.isLoggedIn$.subscribe((isLoggedIn: boolean) => {
      const bodyElm = document.getElementsByTagName('body')[0];
      const isNotLoggedInClass = 'is-not-logged-in';

      isLoggedIn ? bodyElm.classList.remove(isNotLoggedInClass) : bodyElm.classList.add(isNotLoggedInClass);
      this.isMobile = true;
      // this.handleIntercomMobileWidget();
    });
  }

  private checkForUpdate() {
    if (this.swUpdate.isEnabled) {
      // This triggers the update check in a deterministic way - otherwise we only rely on the service worker to do it
      interval(5 * 60 * 1000)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          Logger.log(`SW instance is available: ${!!this.swUpdate}`);

          this.swUpdate
            ?.checkForUpdate()
            .then((isUpdateAvailable) => isUpdateAvailable && Logger.log('New Version Detected'))
            .catch(() => Logger.error('Issue Checking Update'));
        });

      this.swUpdate.versionUpdates
        .pipe(
          filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
          take(1)
        )
        .subscribe(() => {
          Logger.log('New Version Ready');

          this.router.events
            .pipe(
              filter((event) => event instanceof NavigationEnd),
              take(1)
            )
            .subscribe(() => {
              Logger.log('Reloading...');
              window.location.reload();
            });
        });
    }
  }

  handleIntercomMobileWidget() {
    setTimeout(() => {
      const widget: any = document.querySelector('.mobile-intercom-widget');
      const sideBar: any = document.querySelector('sbnb-mobile-sidebar');
      const close: any = document.querySelector('sbnb-close');

      if (widget) {
        if (sideBar) {
          widget.classList.add('mobile-intercom-widget--right');
        } else if (close) {
          widget.classList.add('mobile-intercom-widget--bottom');
        } else {
          widget.classList.remove('mobile-intercom-widget--right');
          widget.classList.remove('mobile-intercom-widget--bottom');
        }
      }
    }, 100);
  }
}
