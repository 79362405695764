import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from '@app/core/app-config';
import { InternalCsService } from '@app/shared/services/internal-cs/internal-cs.service';
import { Logger } from '@app/shared/utils';
import { environment } from '@env/environment';
import { OneSignal } from 'onesignal-ngx';
import { DeviceDetectionService } from '../device-detection/device-detection.service';

@Injectable({
  providedIn: 'root',
})
export class OnesignalService {
  private isInitialised = false;

  constructor(
    private deviceDetectionService: DeviceDetectionService,
    private csService: InternalCsService,
    private http: HttpClient,
    private oneSignal: OneSignal
  ) {}

  public async initialiseOneSignal(id_hash: string, email: string) {
    // OneSignal should be disabled on the Hospitable iOS app due to duplicates with device native notifications
    // OneSignal should absolutely not be enabled in CS mode, otherwise users will start receiving notifications from other users.
    // https://hospitable.slack.com/archives/C010K1EMKAA/p1735294585974279

    // TODO: android?
    if (this.csService.isCS() || this.deviceDetectionService.isIosApp() || environment.production === false) {
      return;
    }

    Logger.log(`Initiating OneSignal for user: ${id_hash} with email: ${email}`);

    if (!this.isInitialised) {
      await this.oneSignal.init({
        appId: config.ONESIGNAL_APP_ID,
        serviceWorkerParam: { scope: '/onesignal' },
        serviceWorkerPath: 'OneSignalSDKWorker.js',
        autoResubscribe: true,
        allowLocalhostAsSecureOrigin: true,
        notifyButton: { enable: false },
      });

      this.isInitialised = true;
    }

    await this.oneSignal.login(id_hash);
    this.oneSignal.User.addTags({ id: id_hash, email: email });

    this.oneSignal.User.PushSubscription.addEventListener('change', (event) => {
      if (event.current.optedIn) this.setWebPushId();
    });
  }

  public async setWebPushId() {
    try {
      const id = this.oneSignal.User.PushSubscription.id;
      this.http
        .post(`${config.API_URL}/user/push-id`, {
          platform: 'onesignal',
          id,
        })
        .subscribe();
    } catch (error) {
      Logger.error('Error setting web push id', error);
    }
  }

  public shutdownOneSignal() {
    Logger.log('Shutting down OneSignal');

    this.oneSignal.User.removeTags(['id', 'email']);
    this.oneSignal.logout();
  }

  public showSlideNotification() {
    if (this.oneSignal.Notifications.isPushSupported() && !this.oneSignal.Notifications.permission) {
      this.oneSignal.Notifications.requestPermission();
    }
  }
}
