import { Component, Input, OnInit } from '@angular/core';
import { OnDestroyComponent } from '@app/shared/components/on-destroy/on-destroy.component';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { PosthogService } from '@app/shared/services/posthog/posthog.service';
import { take, takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-feature-flag-tabs',
  templateUrl: './feature-flag-tabs.component.html',
})
export class FeatureFlagTabsComponent extends OnDestroyComponent implements OnInit {
  public featureFlagMobileNavTabsEnabled: boolean = false;

  constructor(private posthog: PosthogService, private authService: AuthenticationService) {
    super();
  }

  ngOnInit() {
    this.authService.user$.pipe(takeUntil(this.destroyed$)).subscribe((user) => {
      if (user) {
        this.posthog.mobileNavTabsEnabled$.pipe(take(1)).subscribe((enabled) => {
          this.featureFlagMobileNavTabsEnabled = !!enabled;
        });
      }
    });
  }
}
