import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DeviceDetectionService {
  constructor(private breakpointObserver: BreakpointObserver) {}

  private cachedIsIos: boolean;
  private cachedIsAndroid: boolean;

  $isMobile = new BehaviorSubject<boolean>(false);

  init() {
    this.listenForMobile();
  }


  public isIosApp(): boolean {
    if (typeof this.cachedIsIos !== 'undefined') {
      return this.cachedIsIos;
    } else {
      this.cachedIsIos = navigator.userAgent.toLowerCase().includes('smartbnb-ios');
      return this.cachedIsIos;
    }
  }

  public isAndroidApp(): boolean {
    if (typeof this.cachedIsAndroid !== 'undefined') {
      return this.cachedIsAndroid;
    } else {
      this.cachedIsAndroid = navigator.userAgent.toLowerCase().includes('smartbnb-android');
      return this.cachedIsAndroid;
    }
  }

  public isMobileViewport(): Observable<boolean> {
    return this.breakpointObserver.observe(['(max-width: 960px)']).pipe(map((res) => res.matches));
  }

  listenForMobile() {
    this.breakpointObserver.observe(['(max-width: 960px)']).subscribe((state: BreakpointState) => {
      const bodyElm = document.getElementsByTagName('body')[0];

      if (bodyElm) {
        const isMobileClass = 'is-mobile';
        if (state && state.matches) {
          bodyElm.classList.add(isMobileClass);
          this.$isMobile.next(true);
        } else {
          bodyElm.classList.remove(isMobileClass);
          this.$isMobile.next(false);
        }
      }
    });
  }
}
