<sbnb-helptext-wrapper *ngIf="!isDemo" [area]="'global'"></sbnb-helptext-wrapper>
<sbnb-onboarding-banner *ngIf="!isDemo"></sbnb-onboarding-banner>
<sbnb-demo-environment *ngIf="isDemo"></sbnb-demo-environment>
<sbnb-toast-container></sbnb-toast-container>

<sbnb-command-k></sbnb-command-k>

<sbnb-menu-mobile-tabs
  *ngIf="
    featureFlagMobileNavTabsEnabled &&
    (authService.user$ | async) &&
    (deviceDetectionService.$isMobile | async) === true &&
    !shouldHideMenuBasedOnUrl
  "></sbnb-menu-mobile-tabs>
<router-outlet></router-outlet>
