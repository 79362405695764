import { Injectable } from '@angular/core';
import { EntitlementEnum, LoadingState, User } from '@app/shared/interfaces';
import { Store, select } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import * as UsersActions from './users.actions';
import * as UsersSelectors from './users.selectors';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { PosthogService } from '@app/shared/services/posthog/posthog.service';
import { UserPermissionsService } from '@app/core/user-permissions/user-permissions.service';

@Injectable()
export class UsersFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  userLoadingState$ = this.store.pipe(select(UsersSelectors.getUserLoadingState));
  user$: Observable<User> = this.store.pipe(select(UsersSelectors.getUser));
  hasBetaFeaturesEnabled$: Observable<boolean> = this.store.pipe(select(UsersSelectors.hasBetaFeaturesEnabled));

  userTrialConnectAccountCta$ = this.store.pipe(select(UsersSelectors.getUserTrialConnectAccountCta));

  signUpWithAirbnbLoadingState$ = this.store.pipe(select(UsersSelectors.getSignUpWithAirbnbLoadingState));
  signUpWithAirbnbError$ = this.store.pipe(select(UsersSelectors.getSignUpWithAirbnbError));

  public thermostatsEnabled$ = combineLatest([this.posthog.thermostatsEnabled$, this.hasBetaFeaturesEnabled$]).pipe(
    map(([ff, beta]) => beta || ff)
  );

  /* Entitlements */
  entitlementForFeature$ = (feature: EntitlementEnum) =>
    this.userLoadingState$.pipe(
      filter((state) => state === LoadingState.Success || state === LoadingState.Error),
      take(1),
      switchMap((loadingResult) => {
        if (loadingResult === LoadingState.Error) {
          return of({
            entitlement: feature,
            available: false,
            value: '',
            current_value: null,
            unlimited: false,
          });
        }
        return this.store.select(UsersSelectors.getEntitlementForFeature(feature));
      })
    );
  public ownerStatementsEntitlement$ = this.entitlementForFeature$(EntitlementEnum.OwnerStatements);
  public accountingEntitlement$ = this.entitlementForFeature$(EntitlementEnum.Accounting);

  constructor(
    private readonly store: Store,
    private readonly posthog: PosthogService
  ) {}

  loadUser() {
    this.store.dispatch(UsersActions.loadUser());
  }

  signUpWithAirbnb() {
    this.store.dispatch(UsersActions.signUpWithAirbnb());
  }

  signUpWithAirbnbCallback(code: string, state: string) {
    this.store.dispatch(UsersActions.signUpWithAirbnbCallback({ code, state }));
  }

  resetStore() {
    this.store.dispatch(UsersActions.resetStore());
  }
}
