<ng-container *ngIf="knowledge$ | async as state">
  <!-- loaded state -->
  <ng-container *ngIf="!state.loading && !state.error">
    <!-- Empty state -->
    <div
      [hidden]="state.knowledge && state.knowledge.length > 0"
      class="border rounded border-smoke p-12 flex flex-col items-center gap-6">
      <ng-container *ngTemplateOutlet="emptyStateSvg"></ng-container>

      <h3 class="text-lg text-center">
        Knowledge Hub for {{ state.property.name }}
      </h3>

      <div class="font-heading text-center text-gray-600 leading-normal">
        Generate your Knowledge Hub to get the most out of AI.
      </div>

      <div class="text-center text-gray-600 leading-normal">
        By compiling all your property's details, as well as all past conversations with guests, the AI will be able to respond to guests more effectively. You may further enhance the Knowledge Hub by updating generated topics, and importing additional information from a guidebook.
      </div>

      <!-- Uploading PDF -->
      <mat-progress-bar
        *ngIf="state.uploading"
        class="max-w-3xl"
        mode="determinate"
        [value]="
          state.uploadProgress.length > 0 ? state.uploadProgress[state.uploadProgress.length - 1].progress * 100 : 0
        "></mat-progress-bar>
      <div *ngIf="state.uploadProgress.length > 0" class="flex flex-col-reverse gap-2">
        <div
          *ngFor="let progress of state.uploadProgress; let last = last; trackBy: trackProgressFn"
          class="text-center text-smoke font-bold animate-fade-down animate-once animate-duration-200"
          [class.!text-grass-darker]="last"
          [class.animate-pulse]="last">
          {{ progress.message }}...
        </div>
      </div>

      <div class="flex items-center justify-center mt-4 space-x-4">
        <div [hidden]="state.uploading">
          <button
            class="bg-raspberry-darker p-4 rounded text-white cursor-pointer hover:brightness-95"
            (click)="onImportMessageHistoryClicked()">
            Generate Knowledge Hub
          </button>
        </div>
      </div>
      <div [hidden]="state.uploading" class="mt-8 text-sm">
        <a routerLink="/apps/touchstay" class="inline-flex gap-1 items-center">
          <span>Got a</span>
          <span>
            <img class="touchstay-logo h-4 w-4" src="/assets/images/apps/touchstay-logo.png" alt="TouchStay logo" />
            TouchStay
          </span>
          <span>account? Import your existing TouchStay guidebook automatically!</span>
        </a>
      </div>
    </div>

    <!-- Happy path with data -->
    <ng-container *ngIf="state.knowledge && state.knowledge.length > 0">
      <!-- Sample questions -->
      <section
        *ngIf="state.questions && state.questions.length > 0"
        class="border rounded border-smoke bg-white shadow p-4 mt-4 mb-8 top-8 z-10"
        [class.sticky]="!aiToggleOpen">
        <sbnb-collapsible-card [showDivider]="false" (openToggled)="onAiToggle($event)">
          <h4 class="text-grape-darker flex gap-2 items-center" title>
            <img
              class="question-icon"
              src="/assets/iconography/question.svg"
              onload="SVGInject(this)"
              width="16"
              height="16" />
            <span>Ask the AI</span>
          </h4>

          <ng-template sbnbCollapsibleCardContent>
            <div class="text-sm mt-1 text-night-lighter">
              Check how the AI will use the Knowledge Hub to respond to typical questions a guest might ask.
            </div>

            <section class="mt-3 divide-y divide-smoke">
              <div
                *ngFor="let question of state.questions"
                class="hover:bg-smoke-lighter cursor-pointer -mx-4 px-4 group"
                [class.bg-grass-lighter]="question.computed_reply"
                (click)="onTestResponse(question)">
                <div class="flex items-center gap-2 py-2 h-11">
                  <div class="flex-1" [class.strong]="question.computed_reply">
                    {{ question.query }}
                  </div>
                  <mat-spinner *ngIf="question.computed_loading" diameter="16"></mat-spinner>
                  <sbnb-button
                    *ngIf="!question.computed_loading"
                    class="invisible group-hover:visible"
                    size="mini"
                    type="secondary">
                    Ask question
                  </sbnb-button>
                </div>

                <div *ngIf="question.computed_reply" class="text-sm text-night pb-4">
                  {{ question.computed_reply }}
                </div>
              </div>
            </section>

            <!-- Custom question form -->
            <form [formGroup]="customQuestionForm" (ngSubmit)="onAskCustomQuestion()" class="mt-6">
              <div class="flex items-center gap-2">
                <mat-form-field class="flex-grow custom-question-form-field">
                  <input
                    matInput
                    formControlName="customQuestion"
                    placeholder="Ask your own question..."
                    (keyup.enter)="onAskCustomQuestion()" />
                </mat-form-field>
                <sbnb-button
                  size="mini"
                  class="custom-question-button"
                  [disabled]="!customQuestionForm.get('customQuestion')?.value?.trim()">
                  Ask
                </sbnb-button>
              </div>
            </form>

            <!-- Custom question result section -->
            <section *ngIf="customQuestion" class="mt-3 divide-y divide-smoke">
              <div
                class="hover:bg-smoke-lighter cursor-pointer -mx-4 px-4 group"
                [class.bg-grass-lighter]="customQuestion.computed_reply">
                <div class="flex items-center gap-2 py-2 h-11">
                  <div class="flex-1" [class.strong]="customQuestion.computed_reply">
                    {{ customQuestion.query }}
                  </div>
                  <mat-spinner *ngIf="customQuestion.computed_loading" diameter="16"></mat-spinner>
                </div>

                <div *ngIf="customQuestion.computed_reply" class="text-sm text-night pb-4">
                  {{ customQuestion.computed_reply }}
                </div>
              </div>
            </section>
          </ng-template>
        </sbnb-collapsible-card>
      </section>

      <section class="text-xs text-night-lighter">
        <div class="flex items-center gap-2">
          <span>Source:</span>
          <span class="inline-flex items-center" *ngIf="state.source === 'touch_stay'">
            <img class="touchstay-logo h-3 w-3" src="/assets/images/apps/touchstay-logo.png" alt="TouchStay logo" />
            TouchStay
          </span>
          <span *ngIf="state.source === 'message_history'">
            {{ state.property.name }} - Property Details, Message History, House Rules
          </span>
          <span *ngIf="! ['touch_stay', 'message_history'].includes(state.source)">
            {{ state.name }}
          </span>
        </div>
        <div class="flex items-center gap-2">
          <span>Updated:</span>
          <span>{{ state.updated_at | date: 'yyyy-MM-dd HH:mm' }}</span>
        </div>
      </section>

      <section class="flex flex-col lg:flex-row justify-between gap-4 pb-1 border-b border-smoke">
        <div class="flex gap-2 justify-between">
          <h4 class="text-lg text-grape-darker strong">Knowledge for this property</h4>
          <div class="flex lg:hidden">
            <mat-icon class="text-night cursor-pointer hover:brightness-150" [matMenuTriggerFor]="actionsMenu">
              more_vert
            </mat-icon>
          </div>
        </div>

        <div class="flex items-start gap-2">
          <input
            #fileUploadInput
            id="file"
            type="file"
            accept="application/pdf"
            class="hidden"
            (change)="onImportPDFClicked(fileUploadInput)" />

          <sbnb-search-bar
            class="flex-1 min-w-40 hidden xl:block"
            placeholderText="Search knowledge..."
            [enterRequiredToSearch]="false"
            (searchChanged)="searchCriteria.next($event)"></sbnb-search-bar>

          <sbnb-button
            size="mini"
            type="secondary"
            (click)="triggerPosthogFeedback()">
            Give feedback
          </sbnb-button>

          <sbnb-button
            size="mini"
            type="primary"
            matTooltip="Upload a PDF file to help the AI provide better responses to guests. Useful information includes: rules and policies, operating instructions, local area and restaurant recommendations, and safety and emergency notes."
            (click)="triggerFileUploadInput()"
            *ngIf="state.source === 'message_history'">
            Switch to PDF
          </sbnb-button>

          <div *ngIf="state.source === 'pdf'" class="hidden lg:flex w-4 flex-shrink-0 flex-grow-0 mt-1">
            <mat-icon class="text-night cursor-pointer hover:brightness-150" [matMenuTriggerFor]="actionsMenu">
              more_vert
            </mat-icon>
          </div>
        </div>

        <mat-menu #actionsMenu="matMenu">
          <button mat-menu-item (click)="triggerFileUploadInput()">
            <span>Rewrite all information</span>
          </button>
        </mat-menu>
      </section>

      <!-- todo: use `editable` property to disable edit/delete actions -->
      <section *ngFor="let knowledge of filteredKnowledge$ | async">
        <div
          class="flex border-b border-smoke items-center py-4 gap-12 cursor-pointer hover:bg-smoke-lighter px-4"
          (click)="knowledge.computed_expanded = !knowledge.computed_expanded">
          <div class="flex items-center gap-2 flex-1">
            <div class="flex flex-col gap-2">
              <strong class="text-grape-darker">{{ knowledge.topic }}</strong>
            </div>
            <mat-icon class="transition-all scale-105" [class.expand-open]="knowledge.computed_expanded">
              expand_more
            </mat-icon>
          </div>
          <div *ngIf="!knowledge.computed_expanded" class="text-xs hidden md:block">
            {{ knowledge.updated_at | date: 'longDate' }}
          </div>
        </div>

        <!-- parent has multiple children -->
        <div *ngIf="knowledge.computed_expanded">
          <div
            *ngFor="let item of knowledge.items"
            class="flex text-sm py-3 gap-12 pl-8 items-center border-b border-smoke px-4">
            <div class="flex-1 break-words whitespace-pre-line">
              {{ item.content }}
            </div>
            <div class="text-xs hidden md:block">{{ item.updated_at | date: 'longDate' }}</div>
            <div class="w-5">
              <ng-container
                *ngIf="state.editable"
                [ngTemplateOutlet]="actionsTemplate"
                [ngTemplateOutletContext]="{
                  id: item.id,
                  property: state.property.id,
                  content: item.content,
                  topicName: knowledge.topic
                }"></ng-container>
            </div>
          </div>
        </div>
      </section>
    </ng-container>
  </ng-container>

  <!-- loading state -->
  <sbnb-loading-spinner *ngIf="state.loading"></sbnb-loading-spinner>

  <!-- error state -->
  <div *ngIf="state.error" class="error">{{ state.error }}</div>

  <!-- debug -->
  <!-- <pre class="text-xs">{{ state | json }}</pre> -->
  <!-- <pre class="text-xs">{{ filteredKnowledge$ | async | json }}</pre> -->
</ng-container>

<ng-template #actionsTemplate let-id="id" let-property="property" let-content="content" let-topicName="topicName">
  <sbnb-more-menu-trigger [matMenuTriggerFor]="actionsMenu" [suppressClick]="true"></sbnb-more-menu-trigger>
  <mat-menu #actionsMenu="matMenu">
    <button mat-menu-item (click)="openEditDialog(id, property, content, topicName)">
      <span>Edit</span>
    </button>
    <button mat-menu-item (click)="onDeleteItemClicked(id, property)">
      <span>Delete</span>
    </button>
  </mat-menu>
</ng-template>

<ng-template #emptyStateSvg>
  <img src="/assets/images/knowledge-hub/empty-state-book-lightbulb.svg" alt="Empty state" />
</ng-template>
