<h2 class="nomargin">Preview message</h2>
<span class="subheading">See how this message will look in a real-life conversation</span>

<mat-spinner *ngIf="loading" [diameter]="16"></mat-spinner>

<ng-container *ngIf="!loading && conversations">
  <mat-form-field class="fw">
    <mat-select placeholder="Select a conversation" (selectionChange)="conversationChanged($event)">
      <mat-option *ngFor="let conv of conversations" [value]="conv.uuid">
        <div fxLayoutAlign="start center" fxLayoutGap="8px">
          <img class="checkin__icon" [src]="'/assets/iconography/' + conv.guest.platform + '.svg'" />
          <span>
            {{ conv.guest.name }},
            <span *ngIf="conv.checkin" class="text-xs text-night">&nbsp;{{ conv.checkin | date: 'mediumDate' }}</span>
            <span class="text-xs text-night">&nbsp;({{ conv.booking.status.description }})</span>
          </span>
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field *ngIf="requiresTeammateSelection && teammates" class="fw">
    <mat-select placeholder="Select a teammate" (selectionChange)="teammateChanged($event)">
      <mat-option *ngFor="let tm of teammates" [value]="tm.uuid">
        {{ tm.name }} {{ tm.company_name ? '(' + tm.company_name + ')' : '' }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <sbnb-alert-card context="info" *ngIf="simulatingProducts" class="gap-2 mb-4">
    <sbnb-icon icon="alert-triangle"></sbnb-icon>
    <span class="flex-1">
      You are in Preview mode. Actual upsell messages will differ based on available products and calendar availability
      at the time the message is sent. It is possible that no upsell message is added at all.
    </span>
  </sbnb-alert-card>

  <span *ngIf="emailSubject" style="display: block; margin-bottom: 12px">Subject:</span>
  <div *ngIf="emailSubject" class="preview previewSubject" #previewSubject></div>

  <div *ngIf="rulesetData?.team?.notify_via === 'sms' && previewContents.length === 306" class="warning-sms-length">
    <strong>SMS messages are limited to 306 characters.</strong>
    Based on this conversation preview, your message would be truncated to what you see below. Consider shortening your
    template if possible, or switching to email.
  </div>

  <div class="preview pre-wrap" #preview>
    Select a conversation
    {{ requiresTeammateSelection ? 'and teammate' : '' }} above to preview...
  </div>
</ng-container>
