import { HttpClient, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { config } from '@app/core/app-config';
import { Knowledge, KnowledgeState, KnowledgeUploadProgress } from '@app/modules/knowledge/models/knowledge.interface';
import { NotificationType, Property } from '@app/shared/interfaces';
import { PosthogService } from '@app/shared/services/posthog/posthog.service';
import { PusherService } from '@app/shared/services/pusher/pusher.service';
import { SegmentEvent, SegmentIoService } from '@app/shared/services/segmentIo/segment-io.service';
import { ToastNotificationsService } from '@app/shared/services/toast-notifications/toast-notifications.service';
import { Logger } from '@app/shared/utils';
import { BehaviorSubject, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class KnowledgeService implements OnDestroy {
  private pusherSubscription: Subscription = null;

  constructor(
    private http: HttpClient,
    private pusherService: PusherService,
    private segmentIoService: SegmentIoService,
    private posthog: PosthogService,
    private toast: ToastNotificationsService,
    private router: Router
  ) {}

  private _state: BehaviorSubject<KnowledgeState> = new BehaviorSubject({
    editable: null,
    source: null,
    knowledge: null,
    property: null,
    questions: null,
    updated_at: null,
    loading: false,
    error: null,
    uploading: false,
    uploadProgress: [],
  });

  knowledge$ = this._state.asObservable();

  ngOnDestroy(): void {
    if (this.pusherSubscription && this.pusherSubscription.unsubscribe) {
      this.pusherSubscription.unsubscribe();
    }
  }

  fetchKnowledgeForProperty(propertyId: Property['id']) {
    this.http
      .get<{
        data: {
          editable: KnowledgeState['editable'];
          source: KnowledgeState['source'];
          name: KnowledgeState['name'];
          knowledge: Knowledge[];
          property: KnowledgeState['property'];
          questions: KnowledgeState['questions'];
          updated_at: KnowledgeState['updated_at'];
        };
      }>(`${config.API_URL}/properties/${propertyId}/knowledge-hub`)
      .pipe(
        map((res) => {
          const newState: KnowledgeState = {
            editable: res.data.editable,
            source: res.data.source,
            name: res.data.name,
            knowledge: res.data.knowledge.map((item) => ({ ...item, computed_expanded: true })),
            property: res.data.property,
            questions: res.data.questions,
            updated_at: res.data.updated_at,
            loading: false,
            error: null,
            uploading: false,
            uploadProgress: [],
          };
          this._state.next(newState);
          return newState;
        }),
        catchError((error) => {
          const errorState: KnowledgeState = {
            editable: null,
            source: null,
            name: null,
            knowledge: null,
            property: null,
            questions: null,
            updated_at: null,
            loading: false,
            error: error,
            uploading: false,
            uploadProgress: [],
          };
          this._state.next(errorState);
          return throwError(error);
        }),
        startWith({
          editable: null,
          source: null,
          name: null,
          knowledge: null,
          property: null,
          questions: null,
          updated_at: null,
          loading: true,
          error: null,
          uploading: false,
          uploadProgress: [],
        })
      )
      .subscribe((state) => this._state.next(state));
  }

  editKnowledgeItem(id: number, propertyId: Property['id'], content: string): Observable<boolean> {
    return this.http.put(`${config.API_URL}/properties/${propertyId}/knowledge-hub/items/${id}`, { content }).pipe(
      map(() => {
        // find the topic which contains the item
        const topic = this._state.value.knowledge.find((topic) => topic.items.find((item) => item.id === id));
        if (topic) {
          const items = topic.items.map((item) => (item.id === id ? { ...item, content } : item));
          topic.items = items;

          this._state.next({
            ...this._state.value,
            knowledge: this._state.value.knowledge.map((item) => (item.id === topic.id ? topic : item)),
          });
        }
        return true;
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  deleteKnowledgeItem(id: number, propertyId: Property['id']) {
    this.http.delete(`${config.API_URL}/properties/${propertyId}/knowledge-hub/items/${id}`).subscribe({
      next: () => {
        // remove the item from the state

        // find the topic which contains the item
        const topic = this._state.value.knowledge.find((topic) => topic.items.find((item) => item.id === id));

        if (topic) {
          // remove the item from the topic
          const items = topic.items.filter((item) => item.id !== id);
          topic.items = items;
          // if the topic has no items, remove it from the state
          if (topic.items.length === 0) {
            const knowledge = this._state.value.knowledge.filter((item) => item.id !== topic.id);
            this._state.next({
              ...this._state.value,
              knowledge,
            });
          } else {
            // otherwise, update the topic
            this._state.next({
              ...this._state.value,
              knowledge: this._state.value.knowledge.map((item) => (item.id === topic.id ? topic : item)),
            });
          }
        }
      },
    });
  }

  testResponse(propertyId: Property['id'], query: string): Observable<string> {
    return this.http
      .post<{ data: { reply: string } }>(`${config.API_URL}/properties/${propertyId}/knowledge-hub/test`, { query })
      .pipe(map((res) => res.data.reply));
  }

  uploadPDF(propertyId: Property['id'], file: File) {
    const maxSizeInBytes = 100 * 1024 * 1024;

    if (file.size > maxSizeInBytes) {
      this.toast.open('The file is too large. Please choose a smaller file.', 'OK', NotificationType.Error);
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    this._state.next({
      editable: null,
      source: null,
      knowledge: null,
      property: this._state.value.property,
      loading: false,
      error: null,
      uploading: true,
      uploadProgress: [
        {
          message: 'Uploading file',
          progress: 0,
          state: 'uploading',
          property_id: propertyId,
          source_id: null,
          source_type: 'pdf',
          context: null,
        },
      ],
    });

    this.http
      .post<{
        data: { source: { id: number } };
      }>(`${config.API_URL}/properties/${propertyId}/knowledge-hub`, formData, {
        reportProgress: true,
        observe: 'events',
      })
      .subscribe({
        next: (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            if (event.total) {
              const progress = Math.round((100 * event.loaded) / event.total) / 100;
              this._state.next({
                ...this._state.value,
                uploadProgress: [
                  {
                    message: 'Uploading file',
                    progress,
                    state: 'uploading',
                    property_id: propertyId,
                    source_id: null,
                    source_type: 'pdf',
                    context: null,
                  },
                ],
                uploading: true,
              });
            }
          } else if (event.type === HttpEventType.Response) {
            this.listenForPusherUploadProgressEvents(event.body.data.source.id);

            this._state.next({
              ...this._state.value,
              uploading: true,
              uploadProgress: [
                {
                  message: 'Uploading file',
                  progress: 1,
                  state: 'uploading',
                  property_id: propertyId,
                  source_id: null,
                  source_type: 'pdf',
                  context: null,
                },
              ],
            });
          }
        },
        error: (err: HttpErrorResponse) => {
          if (err.status === 413) {
            this.toast.open('The file is too large. Please choose a smaller file.', 'OK', NotificationType.Error);
          } else {
            this.toast.open(
              'An error occurred while uploading the file. Please try again.',
              'OK',
              NotificationType.Error
            );
          }
          this._state.next({
            editable: null,
            source: null,
            knowledge: null,
            property: this._state.value.property,
            loading: false,
            error: 'Upload failed',
            uploading: false,
            uploadProgress: [],
          });
        },
      });
  }

  generateFromMessageHistory(propertyId: Property['id']) {
    this.http
      .post<{
          data: { source: { id: number } };
        }>(`${config.API_URL}/properties/${propertyId}/knowledge-hub/generate`, {}, {
          reportProgress: true,
          observe: 'events',
        })
        .subscribe({
          next: (res) => {
            if (res.type === HttpEventType.Response) {
              const sourceId = res.body.data.source.id;

              this.listenForPusherUploadProgressEvents(sourceId);

              this._state.next({
                ...this._state.value,
                uploading: true,
                uploadProgress: [
                  {
                    message: 'Researching property details',
                    progress: 0.1,
                    state: 'uploading',
                    property_id: propertyId,
                    source_id: sourceId,
                    source_type: 'message_history',
                    context: null,
                  },
                ],
              });
            }
          },
          error: (err: HttpErrorResponse) => {
            this.toast.open(
              'An error occurred while generating the Knowledge Hub from the property message history. Please try again.',
              'OK',
              NotificationType.Error
            );
          }
        });
  }

  listenForPusherUploadProgressEvents(sourceId: number) {
    const subscription = this.pusherService.knowledgeHubImportProgress.subscribe((payload: KnowledgeUploadProgress) => {
      console.log('payload', payload);

      // If the source_id doesn't matches the one we're interested in
      // Then return early, we don't want the crosstalk between multiple sources
      if (payload.source_id !== sourceId) {
        return;
      }

      // Add to the upload progress array
      if (payload.progress === 1 && payload.state === 'ready') {
        Logger.info('Knowledge upload complete');
        subscription.unsubscribe();

        this.segmentIoService.track(SegmentEvent.KnowledgeHubImported, {
          import_type: payload.source_type,
        });

        // Fetch fresh knowledge containing the new items
        this.fetchKnowledgeForProperty(this._state.value.property.id);
      } else if (payload.progress === 1 && payload.state === 'error') {
        // show a toast with the error
        this.toast.open(
          payload.context || 'An error occurred while generating the Knowledge Hub. Please try again.',
          'OK',
          NotificationType.Error
        );

        // refetch fresh state
        this.fetchKnowledgeForProperty(this._state.value.property.id);
      } else {
        // We're still uploading/processing the knowledge
        this._state.next({
          ...this._state.value,
          uploadProgress: [...this._state.value.uploadProgress, payload],
        });
      }
    });
  }
}
