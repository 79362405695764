import { Injectable } from '@angular/core';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { KnowledgeUploadProgress } from '@app/modules/knowledge/models/knowledge.interface';
import { User } from '@app/shared/interfaces';
import { Logger } from '@app/shared/utils';
import { environment } from '@env/environment';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

declare const Pusher: any;

export interface InboxUpdatePusherPayload {
  uuid: string;
}
@Injectable({
  providedIn: 'root',
})
export class PusherService {
  private pusher: any;

  // Pusher untyped channels
  private channel_global: any;
  private channel_user: any;
  private channel_team: any;

  private channelNames = {
    global: '',
    user: '',
    team: '',
  };

  private userIdHash: string;
  private userObj: User;

  private _inboxListUpdate: Subject<InboxUpdatePusherPayload> = new Subject();
  public inboxListUpdate = this._inboxListUpdate.asObservable();

  private _knowledgeHubImportProgress: Subject<KnowledgeUploadProgress> = new Subject();
  public knowledgeHubImportProgress = this._knowledgeHubImportProgress.asObservable();

  private _listingsUpdate: ReplaySubject<any> = new ReplaySubject();
  public listingsUpdate = this._listingsUpdate.asObservable();

  private _onboardingUpdate: ReplaySubject<any> = new ReplaySubject();
  public onboardingUpdate = this._onboardingUpdate.asObservable();

  private _bookingComUpdate: Subject<any> = new Subject();
  public bookingComUpdate$ = this._bookingComUpdate.asObservable();

  // When we're connecting an Airbnb PMS account, we want to know an account has connected or failed to connect
  // so that we can show the connection status
  private _airbnbConnectionStatusUpdate: Subject<any> = new Subject();
  public airbnbConnectionStatusUpdate$ = this._airbnbConnectionStatusUpdate.asObservable();

  private _accountingConnectionUpdate: Subject<{
    type: 'failed_onboarding' | 'finished_onboarding' | 'ledger_accounts_synced';
    connection: any;
  }> = new Subject();
  public accountingConnectionUpdate$ = this._accountingConnectionUpdate.asObservable();

  constructor(private notificationsService: NotificationsService) {}

  public init() {
    Logger.info(`Subscribing to Pusher`);

    this.userObj = JSON.parse(localStorage.getItem('user'));
    this.userIdHash = this.userObj.id_hash;

    this.channelNames = {
      global: 'users',
      user: `users.${this.userIdHash}`,
      team: `teams.${this.userObj.team.id}`,
    };

    this.pusher = new Pusher(environment.pusher.key, {
      cluster: environment.pusher.cluster,
      encrypted: true,
    });

    // Subscribe to all our channels
    Object.entries(this.channelNames).forEach((entry) => {
      const [key, value] = entry;

      this[`channel_${key}`] = this.pusher.subscribe(value);
    });

    this.registerForNotificationNew();
    this.registerForNotificationUpdate();
    this.registerForNotificationDelete();
    this.registerForInboxListUpdates();
    this.registerForKnowledgeBaseUpdates();
    this.registerForListingReviewUpdates();
    this.registerForOnboardingUpdates();
    this.registerForBookingComUpdates();
    this.registerForAirbnbConnectionStatusUpdates();
    this.registerForAccountingConnectionUpdates();
  }

  public closeDown() {
    if (this.pusher) {
      // Subscribe to all our channels
      Object.entries(this.channelNames).forEach((entry) => {
        const [key, value] = entry;

        this.pusher.unsubscribe(value);
      });
    }
  }

  private registerForAirbnbConnectionStatusUpdates() {
    if (this.channel_user) {
      this.channel_user.bind('channel.airbnb.connected', (data: unknown) => {
        this._airbnbConnectionStatusUpdate.next({ connectionSuccess: true, data });
        Logger.log(`Pusher service: Airbnb connection status update event received. ${JSON.stringify(data)}`);
      });

      this.channel_user.bind('channel.airbnb.error', (data: unknown) => {
        this._airbnbConnectionStatusUpdate.next({ connectionSuccess: false, data });
        Logger.log(`Pusher service: Airbnb connection status error event received. ${JSON.stringify(data)}`);
      });
    }
  }

  private registerForInboxListUpdates() {
    if (this.channel_team) {
      this.channel_team.bind('conversations.overview.updated', (data: InboxUpdatePusherPayload) => {
        this._inboxListUpdate.next(data);
        Logger.log(`Pusher service: Inbox list event received. uuid: ${data.uuid}`);
      });

      // push when the connection changes back to Connection too
      this.pusher.connection.bind('connected', () => {
        Logger.info('Pusher status changed to connected');
        this._inboxListUpdate.next();
      });
    }
  }

  private registerForKnowledgeBaseUpdates() {
    if (this.channel_team) {
      this.channel_team.bind('knowledge_hub.import.progress', (data: KnowledgeUploadProgress) => {
        this._knowledgeHubImportProgress.next(data);
        Logger.log(`Pusher service: Knowledge Hub import progress event received. id: ${data.source_id}`);
      });
    }
  }

  public getConversationUpdatesForAThreadUuid(uuid: string): Observable<InboxUpdatePusherPayload> {
    return this.inboxListUpdate.pipe(
      filter((item) => item && 'uuid' in item),
      filter((item) => item.uuid === uuid)
    );
  }

  private registerForNotificationNew() {
    this.channel_global.bind('notification.new', (data) => {
      this.notificationsService.addNotification(data);
    });

    this.channel_user.bind('notification.new', (data) => {
      this.notificationsService.addNotification(data);
    });
  }

  private registerForNotificationUpdate() {
    this.channel_global.bind('notification.update', (data) => {
      this.notificationsService.updateNotification(data);
    });

    this.channel_user.bind('notification.update', (data) => {
      this.notificationsService.updateNotification(data);
    });
  }

  private registerForNotificationDelete() {
    this.channel_global.bind('notification.delete', (data) => {
      this.notificationsService.deleteNotification(data);
    });

    this.channel_user.bind('notification.delete', (data) => {
      this.notificationsService.deleteNotification(data);
    });
  }

  private registerForListingReviewUpdates() {
    this.channel_user.bind('channel.listings.update', (data) => {
      this._listingsUpdate.next(data);
    });
  }

  private registerForOnboardingUpdates() {
    this.channel_user.bind('onboarding.update', (data) => {
      this._onboardingUpdate.next(data);
    });
  }

  private registerForBookingComUpdates() {
    this.channel_user.bind('booking.com.update', (data) => {
      this._bookingComUpdate.next(data);
    });
  }

  private registerForAccountingConnectionUpdates() {
    if (!this.channel_team) {
      return;
    }

    this.channel_team.bind('connection.finished_onboarding', (data) => {
      this._accountingConnectionUpdate.next({ type: 'finished_onboarding', connection: data.connection });
    });

    this.channel_team.bind('connection.ledger_accounts_synced', (data) => {
      this._accountingConnectionUpdate.next({ type: 'ledger_accounts_synced', connection: data.connection });
    });

    this.channel_team.bind('connection.failed_onboarding', (data) => {
      this._accountingConnectionUpdate.next({ type: 'failed_onboarding', connection: data.connection });
    });
  }
}
